import { useCallback } from 'react'
import { getCurrencyRate } from '@/services/relysia-queries'
import { useQuery } from '@tanstack/react-query'

export default function useExchangeRates() {
  const {
    data: exchangeRate,
    isLoading,
    isError,
  } = useQuery(['exchangeRate'], getCurrencyRate)

  const usdToBsv = useCallback(
    (price) => {
      if (!exchangeRate) return NaN
      const bsv = price ? price / (100000000 * exchangeRate) : 0.0
      return Number(bsv)
    },
    [exchangeRate],
  )

  const bsvToUsd = useCallback(
    (price) => {
      if (!exchangeRate) return NaN
      const bsv = price ? price * (100000000 * exchangeRate) : 0.0
      return Number(bsv)
    },
    [exchangeRate],
  )

  return {
    usdToBsv,
    bsvToUsd,
    isLoading,
    isError,
  }
}
