import React, { Fragment, memo, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { formatDate } from '@/utils/dates'
import { useSelector } from 'react-redux'
import { firebaseGetErrorsBet, getProfileSetting } from '@/firebase/utils'
import { successMsg } from '@/utils/toast'
import {
  getLeagueByName,
  getSportByName,
  getTeamByName,
} from '@/constants/countries-leagues'
import { NumberChip, renderNumber2 } from '@/utils/helper'
import { StatusChip } from './StatusChip'
import useExchangeRates from '@/hooks/useExchangeRates'
import moment from 'moment'
import authSelector from '@/redux/selectors/auth'
import { FormattedMessage, useIntl } from 'react-intl'
import CustomImage from '@/utils/customImage'
import {
  Btcsv,
  DoubleCheck,
  LilbitLogo,
  LinkChain,
  Profile,
} from '@/icons/index'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  LinkIcon,
  XIcon,
} from '@heroicons/react/solid'
import { DuplicateIcon } from '@heroicons/react/outline'

const calcSingleBet = (bet) => {
  let winPrice = 0

  const { betPrice, price } = bet || {
    betPrice: 0,
    price: 0,
  }

  if (Number(price) > 0) {
    winPrice = Number(betPrice) * (Number(price) / 100)
  } else {
    winPrice = Number(betPrice) * (100 / Math.abs(Number(price)))
  }

  return winPrice
}

const removeDollarSign = (amount) => {
  return amount.replace('$', '')
}

const SingleSlip = memo(
  ({ slipData, mainData, isMulti, topRounded, bottomRounded, slipBorder }) => {
    const matchDesc = slipData?.future
      ? slipData.future
      : `${slipData?.match?.home_team} vs ${slipData?.match?.away_team}`

    const sport = getSportByName(slipData?.match?.sport)
    const league = getLeagueByName(slipData?.match?.league)
    const teamInformation = getTeamByName({
      teamName: slipData?.teamName,
      league: league.name,
      sport: sport.name,
    })
    return (
      <div
        className={`bg-white/5 overflow-hidden ${
          topRounded ? 'rounded-t-xl' : ''
        } ${bottomRounded ? 'rounded-b-xl' : ''} ${
          slipBorder ? 'border-t-2 border-white/30 border-dotted' : ''
        }`}
      >
        <div className="bg-white/5 py-2 px-6 flex flex-row items-center justify-start gap-2 relative">
          <sport.logo className="w-7 h-7" />
          <div className="w-6 h-6 overflow-hidden rounded-md bg-white flex justify-center items-center">
            <league.logo />
          </div>

          <h4 className="overflow-ellipsis flex-1 text-left font-inter font-medium text-xs">
            {matchDesc}
          </h4>
        </div>
        <div className="px-6 py-4">
          <div className="flex flex-row flex-wrap items-center gap-3 relative">
            <div>
              {slipData?.teamName || slipData?.match?.sport === 'soccer' ? (
                typeof teamInformation?.logo === 'function' ? (
                  <div className="relative w-10 h-10 rounded-full bg-white overflow-hidden flex justify-center items-center">
                    <teamInformation.logo />
                  </div>
                ) : (
                  <CustomImage src={teamInformation?.logo} cls={'w-10 h-10'} />
                )
              ) : (
                <div className="relative w-10 h-10 rounded-full bg-white overflow-hidden flex justify-center items-center">
                  <league.logo />
                </div>
              )}
            </div>
            <div className="flex-1">
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {slipData?.market_name}
                </span>
              </div>
              <div className="flex flex-row items-center space-x-2">
                <span className="text-sm font-semibold font-inter text-[#A6EF67]">
                  {!slipData?.teamName
                    ? slipData?.name
                    : slipData?.teamName && slipData?.teamName !== 'draw'
                    ? 'Winner'
                    : 'Draw'}
                </span>
                <div>
                  <NumberChip
                    number={renderNumber2(slipData?.price)}
                    className="bg-white/5 text-[#F7941D]"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-between space-x-1 text-sm font-medium font-inter text-white relative">
                {!slipData?.teamName ? null : slipData?.teamName &&
                  slipData?.teamName !== 'draw' ? (
                  <div>
                    <span>
                      {slipData?.bet_points
                        ? slipData?.teamName +
                          ' ' +
                          '(' +
                          slipData?.bet_points +
                          ')'
                        : slipData?.teamName}
                    </span>
                  </div>
                ) : null}
                {slipData.grade && slipData.grade === 'win' ? (
                  <DoubleCheck className="w-6 h-6 text-green-500 stroke-2" />
                ) : slipData?.refunded ? (
                  <StatusChip
                    status={'Push'}
                    className="bg-[#F7941D]/20 text-[#F7941D]"
                  />
                ) : (
                  slipData.grade === 'loss' && (
                    <XIcon className="w-6 h-6 text-red-500 stroke-2" />
                  )
                )}
              </div>
            </div>
            {slipData?.match?.start_date ? (
              <div className="font-inter font-normal text-[0.60rem] absolute -right-3.5 -top-[0.65rem]">
                <FormattedMessage
                  id="betSlipModal.startTime.title"
                  defaultMessage={'Start Time'}
                />
                {': ' +
                  formatDate(slipData?.match?.start_date, 'MM/DD/YYYY') +
                  ' ' +
                  formatDate(slipData?.match?.start_date, 'hh:mm A')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  },
)
SingleSlip.displayName = 'SingleSlip'

export default function BetSlipsModel({
  isOpen,
  onClose,
  amount,
  data,
  onPressPrev,
  onPressNext,
  loader,
  nextBetIndex,
  prevBetIndex,
  isFailed = false,
  hideNavigation,
  isLive,
}) {
  const intl = useIntl()
  const [touchStart, setTouchStart] = useState(null)

  const [touchEnd, setTouchEnd] = useState(null)
  const [value, setValue] = useState()
  const { user, isAuthenticated } = useSelector(authSelector)

  const { usdToBsv, bsvToUsd } = useExchangeRates()
  const [errorLogs, setErrorLogs] = useState([])
  const uid = data?.uid
  const [betPlacedUser, setBetPlacedUser] = useState({})
  const isMultiBet = data?.betSlips ? true : false

  const totalBet = (
    Number(isMultiBet ? data?.price : data?.betPrice) || 0.0
  )?.toFixed(7)
  const totalPayout =
    Number(isMultiBet ? data?.possibleWinPrice : calcSingleBet(data)) || 0.0

  const countTotalPayout = (totalBet, totalPayout) => {
    const sum = Number(totalBet) + Number(totalPayout)
    return sum.toFixed(7)
  }

  useEffect(() => {
    setValue(countTotalPayout(totalBet, totalPayout))
  }, [totalBet, totalPayout])

  useEffect(() => {
    getProfile(uid)
  }, [uid])
  const getErrorLogs = async (id) => {
    try {
      const errorLogsData = await firebaseGetErrorsBet(id)
      setErrorLogs(errorLogsData)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (isFailed && data) {
      getErrorLogs(data?.betID)
    }
  }, [isFailed, data])
  const keyListener = React.useCallback(
    (event) => {
      if (event.key === 'ArrowRight') {
        if (onPressNext && nextBetIndex > -1) {
          onPressNext(nextBetIndex)
        }
      } else if (event.key === 'ArrowLeft') {
        if (onPressPrev && prevBetIndex > -1) {
          onPressPrev(prevBetIndex)
        }
      }
    },
    [nextBetIndex, prevBetIndex],
  )

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener('keydown', keyListener, false)
      return () => {
        document.removeEventListener('keydown', keyListener, false)
      }
    }
  }, [keyListener])

  const minSwipeDistance = 50

  const onTouchStart = (e) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      if (onPressPrev && prevBetIndex > -1) {
        onPressPrev(prevBetIndex)
      }
    } else if (isRightSwipe) {
      if (onPressNext && nextBetIndex > -1) {
        onPressNext(nextBetIndex)
      }
    }
  }

  async function getProfile(uid) {
    const res = await getProfileSetting(uid)

    if (res) {
      setBetPlacedUser(res)
    }
  }

  const moveToBetLink = (txId) => {
    window.open(`https://whatsonchain.com/tx/${txId}`, '_blank')
  }

  const totalOdds = isMultiBet
    ? data?.betSlips?.reduce((acc, curr) => (acc += curr?.price), 0)
    : data?.price

  if (!data) return null

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 bg-white "
        onClose={onClose}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 backdrop-blur-md bg-black/90`} />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="flex flex-row items-center justify-center">
                <Dialog.Panel
                  className={
                    'transform transition-all shadow-2xl w-full relative'
                  }
                >
                  <div
                    className="absolute top-3 right-3 p-1 rounded-full hover:bg-white/5 duration-300 cursor-pointer z-30"
                    onClick={onClose}
                  >
                    <XIcon className="w-5 h-5 text-gray-300" />
                  </div>
                  <div className="absolute top-[50%] right-[11px]">
                    {!hideNavigation && (
                      <div className="flex items-center justify-center w-[40px] h-[40px] bg-white/10 rounded-full cursor-pointer relative">
                        {loader ? (
                          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5"></div>
                        ) : (
                          <ChevronRightIcon
                            onClick={() => {
                              if (onPressNext && nextBetIndex > -1) {
                                onPressNext(nextBetIndex)
                              }
                            }}
                            className="w-8 h-8 text-gray-400"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="absolute top-[50%] left-[11px]">
                    {!hideNavigation && (
                      <div className="flex items-center justify-center w-[40px] h-[40px] bg-white/10 rounded-full cursor-pointer relative">
                        <ChevronLeftIcon
                          onClick={() => onPressPrev(prevBetIndex)}
                          className="w-8 h-8 text-gray-400"
                        />
                      </div>
                    )}
                  </div>
                  <div className="rounded-b-xl bg-white/5 pt-6 px-6 overflow-auto scroller max-h-[calc(100vh-200px)] min-w-[23rem] md:min-w-[28rem]  ">
                    {/* Info */}
                    <div className="space-y-3 flex flex-col items-center">
                      <div className="flex flex-row items-center justify-center text-base font-semibold font-inter">
                        <h4>
                          <FormattedMessage
                            id="betSlipModal.sportsID.title"
                            defaultMessage={'Sports ID'}
                          />{' '}
                          {data?.betID}
                        </h4>
                        <div className="flex flex-row items-center gap-2 ml-2 relative w-14 justify-around">
                          <DuplicateIcon
                            className="shrink-0 w-6 h-6 cursor-pointer"
                            onClick={() => {
                              window.navigator.clipboard.writeText(data?.betID)
                              successMsg(
                                intl.formatMessage(
                                  {
                                    id: 'success.betIdCopied',
                                    defaultMessage: `BetID ${data?.betID} Successfully Copied`,
                                  },
                                  { betID: data?.betID },
                                ),
                              )
                            }}
                          />
                          <LinkChain
                            onClick={() => {
                              window.navigator.clipboard.writeText(
                                `${window.location.host}/embed?uid=${
                                  data['uid'] ? data['uid'] : user['uid']
                                }&betId=${data.betID}`,
                              )
                              successMsg(
                                intl.formatMessage({
                                  id: 'success.betSlipLinkCopied',
                                  defaultMessage:
                                    'Bet slip link successfully copied',
                                }),
                              )
                            }}
                            className="w-5 h-5 cursor-pointer"
                          />
                        </div>
                      </div>
                      <div className="text-[#98A2B3] font-inter space-x-2 flex flex-row items-center justify-center">
                        <h4 className="font-normal text-base">
                          <FormattedMessage
                            id="betSlipModal.placedBy.title"
                            defaultMessage={'Placed By'}
                          />
                        </h4>
                        <div className="bg-white/5 rounded-2xl px-2.5 py-1 flex flex-row items-center gap-1 relative">
                          <Profile className="w-5 h-5 text-gray-300" />
                          {betPlacedUser.profileStatus === 'public' ||
                          (betPlacedUser.profileStatus === 'private' &&
                            user?.uid === data?.uid) ||
                          user?.roleType === 'admin' ? (
                            <h4 className="font-medium text-sm capitalize">
                              {betPlacedUser?.username ||
                                betPlacedUser?.displayName}
                            </h4>
                          ) : (
                            <h4 className="font-medium text-sm capitalize">
                              {
                                <FormattedMessage
                                  id="betSlipModal.hidden.title"
                                  defaultMessage={'Hidden'}
                                />
                              }
                            </h4>
                          )}
                        </div>
                        {isLive && (
                          <div className="bg-blue-300/30 rounded-2xl px-2.5 py-1 flex flex-row items-center gap-1 relative">
                            <p className="text-blue-500 font-bold text-xs">
                              <FormattedMessage
                                id="betSlipModal.liveBet.title"
                                defaultMessage={'Live Bet'}
                              />
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="font-inter font-normal text-xs">
                        <FormattedMessage
                          id="betSlipModal.on.text"
                          defaultMessage={'on'}
                        />
                        {' ' +
                          formatDate(data?.placedAt, 'MM/DD/YYYY') +
                          ' ' +
                          formatDate(data?.placedAt, 'hh:mm A')}
                      </div>
                    </div>
                    {/* Slips */}
                    <div className="mt-6 ml-[2rem] w-[16rem] sm:w-[21rem] mb-2">
                      {isMultiBet ? (
                        <div>
                          {data?.betSlips?.map((slip, index) => {
                            return (
                              <SingleSlip
                                key={index}
                                slipData={slip}
                                mainData={data}
                                topRounded={!index}
                                slipBorder={
                                  data?.betSlips?.length === index + 1 && index
                                }
                                bottomRounded={
                                  data?.betSlips?.length === index + 1
                                }
                                isMulti={true}
                              />
                            )
                          })}
                        </div>
                      ) : (
                        <div>
                          <SingleSlip
                            slipData={data}
                            mainData={data}
                            topRounded
                            bottomRounded
                            slipBorder={false}
                            isMulti={false}
                          />
                        </div>
                      )}
                    </div>
                    {isFailed && Boolean(errorLogs?.length) && (
                      <div className="space-y-5 mt-5 max-w-[23rem] md:max-w-[28rem]">
                        <h1 className="">
                          <FormattedMessage
                            id="betSlipModal.errorLogs.title"
                            defaultMessage={'Error Logs'}
                          />
                        </h1>
                        <div className="w-full  space-y-2  justify-center max-h-[160px] overflow-y-auto">
                          {errorLogs
                            .sort(function (a, b) {
                              return moment.utc(b.time).diff(moment.utc(a.time))
                            })
                            .map((data, index) => (
                              <div
                                key={data.time}
                                className={`${
                                  index % 2 === 0
                                    ? 'bg-white/5'
                                    : 'bg-transparent'
                                }  w-full`}
                              >
                                <p
                                  className={` w-full break-words text-start text-xs p-1`}
                                >
                                  <FormattedMessage
                                    id="betSlipModal.date.title"
                                    defaultMessage={'Date: '}
                                  />{' '}
                                  {moment(data.time).format('lll')}
                                </p>
                                <p
                                  className={`w-full break-words text-start text-xs p-1`}
                                >
                                  {data.error}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    <div className="mt-8">
                      <div className="flex items-center justify-center relative">
                        <LilbitLogo className="w-[6.5rem] h-8" />
                      </div>
                      <div className="mt-3 mb-10 space-y-4 font-inter text-xs">
                        <div className="flex flex-row items-center justify-between">
                          <h3 className="font-semibold">
                            <FormattedMessage
                              id="betSlipModal.totalOdds.title"
                              defaultMessage={'Total Odds'}
                            />
                          </h3>
                          <h4 className="font-medium">
                            {renderNumber2(totalOdds)}
                          </h4>
                        </div>
                        <div className="flex flex-row items-start justify-between">
                          <h3 className="font-semibold">
                            <FormattedMessage
                              id="betSlipModal.totalBet.title"
                              defaultMessage={'Total bet'}
                            />
                          </h3>
                          {data?.betTxId ? (
                            <div
                              className="font-medium hover:cursor-pointer flex flex-col items-end"
                              onClick={() => moveToBetLink(data?.betTxId)}
                            >
                              <span>
                                {' '}
                                $
                                {data?.isBsvBet
                                  ? bsvToUsd(
                                      isMultiBet ? data?.price : data?.betPrice,
                                    ).toFixed(7)
                                  : totalBet}
                              </span>
                              {data?.isBsvBet && (
                                <div class="has-tooltip flex space-x-0.5">
                                  <span class="tooltip rounded shadow-lg p-1 bg-gray-700 text-gray-100 -mt-6">
                                    {isMultiBet ? data?.price : data?.betPrice}
                                  </span>
                                  <Btcsv className="w-4 h-4" />
                                  <span>{totalBet}</span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="font-medium">
                              <span>
                                {' '}
                                ${' '}
                                {data?.isBsvBet
                                  ? bsvToUsd(
                                      isMultiBet ? data?.price : data?.betPrice,
                                    ).toFixed(7)
                                  : totalBet}
                              </span>
                              {data?.isBsvBet && (
                                <div class="has-tooltip flex space-x-0.5">
                                  <span class="tooltip rounded shadow-lg p-1 bg-gray-700 text-gray-100 -mt-6">
                                    {isMultiBet ? data?.price : data?.betPrice}
                                  </span>
                                  <Btcsv className="w-4 h-4" />
                                  <span>{totalBet}</span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {data?.refunded ? (
                          <div className="flex flex-row items-start justify-between">
                            <h3 className="font-semibold">
                              <FormattedMessage
                                id="betSlipModal.refund.title"
                                defaultMessage={'Refund'}
                              />
                            </h3>
                            {data?.refundedTxId ? (
                              <h4
                                className="font-medium hover:cursor-pointer hover:underline"
                                onClick={() =>
                                  moveToBetLink(data?.refundedTxId)
                                }
                              >
                                ${totalBet}
                              </h4>
                            ) : (
                              <h4 className="font-medium">${totalBet}</h4>
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="flex flex-row items-start justify-between">
                              <h3 className="font-semibold">
                                <FormattedMessage
                                  id="betSlipModal.estimatedPayout.title"
                                  defaultMessage={'Est. Payout'}
                                />
                              </h3>
                              {data?.winTxId ? (
                                <div
                                  className="font-medium hover:cursor-pointer hover:underline"
                                  onClick={() => moveToBetLink(data?.winTxId)}
                                >
                                  <span>
                                    {' '}
                                    $
                                    {data?.isBsvBet
                                      ? bsvToUsd(totalPayout).toFixed(7)
                                      : amount
                                      ? removeDollarSign(amount)
                                      : totalPayout.toFixed(7)}
                                  </span>
                                  {data?.isBsvBet && (
                                    <div class="has-tooltip flex space-x-0.5">
                                      <span class="tooltip rounded shadow-lg p-1 bg-gray-700 text-gray-100 -mt-6">
                                        {data?.possibleWinPrice}
                                      </span>
                                      <Btcsv className="w-4 h-4" />
                                      <span>
                                        {data?.possibleWinPrice.toFixed(7)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="font-medium flex flex-col items-end">
                                  <span>
                                    {' '}
                                    $
                                    {data?.isBsvBet
                                      ? bsvToUsd(
                                          data?.possibleWinPrice,
                                        ).toFixed(7)
                                      : totalPayout.toFixed(7)}
                                  </span>
                                  {data?.isBsvBet && (
                                    <div class="has-tooltip flex space-x-0.5">
                                      <span class="tooltip rounded shadow-lg p-1 bg-gray-700 text-gray-100 -mt-6">
                                        {data?.possibleWinPrice}
                                      </span>
                                      <Btcsv className="w-4 h-4" />
                                      <span>
                                        {data?.possibleWinPrice.toFixed(7)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
        +{' '}
      </Dialog>
    </Transition>
  )
}
